exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-buyers-jsx": () => import("./../../../src/pages/buyers.jsx" /* webpackChunkName: "component---src-pages-buyers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insurance-and-finance-jsx": () => import("./../../../src/pages/insurance-and-finance.jsx" /* webpackChunkName: "component---src-pages-insurance-and-finance-jsx" */),
  "component---src-pages-listings-jsx": () => import("./../../../src/pages/listings.jsx" /* webpackChunkName: "component---src-pages-listings-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-moorings-jsx": () => import("./../../../src/pages/moorings.jsx" /* webpackChunkName: "component---src-pages-moorings-jsx" */),
  "component---src-pages-more-luxe-jsx": () => import("./../../../src/pages/more-luxe.jsx" /* webpackChunkName: "component---src-pages-more-luxe-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-sellers-jsx": () => import("./../../../src/pages/sellers.jsx" /* webpackChunkName: "component---src-pages-sellers-jsx" */),
  "component---src-pages-training-jsx": () => import("./../../../src/pages/training.jsx" /* webpackChunkName: "component---src-pages-training-jsx" */),
  "component---src-pages-transport-jsx": () => import("./../../../src/pages/transport.jsx" /* webpackChunkName: "component---src-pages-transport-jsx" */),
  "component---src-pages-yachts-for-charter-jsx": () => import("./../../../src/pages/yachts-for-charter.jsx" /* webpackChunkName: "component---src-pages-yachts-for-charter-jsx" */),
  "component---src-templates-charter-listing-jsx": () => import("./../../../src/templates/charterListing.jsx" /* webpackChunkName: "component---src-templates-charter-listing-jsx" */),
  "component---src-templates-listing-jsx": () => import("./../../../src/templates/listing.jsx" /* webpackChunkName: "component---src-templates-listing-jsx" */)
}

